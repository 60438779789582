import { Card, CardList, H2, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { AppToaster } from '../AppToaster';
import { ThirdPartyIntegrationProvider } from '../contexts/ThirdPartyIntegrationContext';
import IntegrationList from './Integration/IntegrationList';
import styles from './ThirdPartyIntegration.module.css';

export default function ThirdPartyIntegration() {
  const [toasterMessage, setToasterMessage] = useState(null);

  const allServices = [
    {
      logo: 'https://framerusercontent.com/images/arCkGPasoPWFaSyK1gWYdR4.png',
      title: 'Salesforce',
    },
    {
      logo: 'https://framerusercontent.com/images/5rmIjDB3mgLiLfXSI9z7zuDdM.png',
      title: 'HubSpot',
    },
    {
      logo: 'https://framerusercontent.com/images/YeUirRTyZp6tRWTw2Ok7Jv5wQ.svg',
      title: 'Microsoft Dynamics 365 Sales',
    },
    {
      logo: 'https://framerusercontent.com/images/V2g6uJNBdKfO58FUu1UppI2n2U.png',
      title: 'Pipedrive',
    },
    {
      logo: 'https://framerusercontent.com/images/HFlbUb50NDuldNUvUaRtMAgo84Y.png',
      title: 'Zoho CRM',
    },
    {
      logo: 'https://framerusercontent.com/images/7u93DvNHsxtT2XDsh07jAm6DOw.svg',
      title: 'Close',
    },
    {
      logo: 'https://framerusercontent.com/images/9BRMfgrRMfch8JfI3ZBsJxUSjEs.svg',
      title: 'Zendesk Sell',
    },
    {
      logo: 'https://framerusercontent.com/images/kfWkdm2h9yZjJo0UBVu1J8F8zk.svg',
      title: 'Insightly',
    },
    {
      logo: 'https://framerusercontent.com/images/pWrSPPXhh1ucwop80HZcgAktag.svg',
      title: 'Copper',
    },
    {
      logo: 'https://framerusercontent.com/images/llY5VIX36JcPCrdfwqezaBajqO0.png',
      title: 'Keap',
    },
    {
      logo: 'https://framerusercontent.com/images/IPradnXlDQ9GSzScLceqBfGur5U.png',
      title: 'Freshsales',
    },
    {
      logo: 'https://framerusercontent.com/images/kddCx8J6pMEEeFa3RWqizKZEClQ.svg',
      title: 'Outreach',
    },
    {
      logo: 'https://framerusercontent.com/images/igfnvCOV1wDMcxQcZLDesRTcD6I.png',
      title: 'Gong',
    },
    {
      logo: 'https://framerusercontent.com/images/C059qUueqR4lx1F3afJY8R5oYIw.png',
      title: 'Salesloft',
    },
    {
      logo: 'https://framerusercontent.com/images/BDkOg5v9OvRvvh4xmbmkJ8dTYQ.jpeg',
      title: 'Apollo.io',
    },
    {
      logo: 'https://framerusercontent.com/images/LDngbfBRdxAJuwtXn92Rpx0Go.jpeg',
      title: 'Chorus.ai',
    },
    {
      logo: 'https://framerusercontent.com/images/hGPDAHoguwGJDLba0dXa5ehpGIw.svg',
      title: 'Guru',
    },
    {
      logo: 'https://framerusercontent.com/images/08pNECJRCNA29yF7KFGz6o9E0sw.svg',
      title: 'Klaviyo',
    },
    {
      logo: 'https://framerusercontent.com/images/Wk4WFoKTBBYqFGNnsmoPQwDo.svg',
      title: 'Marketo',
    },
    {
      logo: 'https://framerusercontent.com/images/j3YjImHNokKfeJVCEGQMFG4FZl8.png',
      title: 'Pardot',
    },
    {
      logo: 'https://framerusercontent.com/images/zHUrVVIB3gj7HbvuKIMw6EmpnL8.svg',
      title: 'Oracle Eloqua',
    },
    {
      logo: 'https://framerusercontent.com/images/i8J3zxuIcgjrpkoROau6lGCrA.svg',
      title: 'Sailthru',
    },
    {
      logo: 'https://framerusercontent.com/images/pWo2wLOPHI2s9QpznptZrYhGR4.png',
      title: 'ActiveCampaign',
    },
    {
      logo: 'https://framerusercontent.com/images/Q0qO789ZdVrSN9FqeYm1Nz9e6E8.svg',
      title: 'Iterable',
    },
    {
      logo: 'https://framerusercontent.com/images/xlxOkm9dIh3YUTFUa6ISN7fSRCM.svg',
      title: 'AWeber',
    },
    {
      logo: 'https://framerusercontent.com/images/D8I2sleYUpyIIwFZHEsnbtQP3Z0.svg',
      title: 'Campaign Monitor',
    },
    {
      logo: 'https://framerusercontent.com/images/bbleTuSBDCexlsRD7hMIqnc55NQ.png',
      title: 'Google Analytics',
    },
    {
      logo: 'https://framerusercontent.com/images/BwKf18WwX5WqweLWDY93pdU9TU.png',
      title: 'Google Search Console',
    },
    {
      logo: 'https://framerusercontent.com/images/xJFZ0ovoCReTxcdPFzrFrzho.png',
      title: 'Mixpanel',
    },
    {
      logo: 'https://framerusercontent.com/images/cuLeijsT0iEo2LOVKL5Fa0J1ZP0.svg',
      title: 'Google Analytics GA4',
    },
    {
      logo: 'https://framerusercontent.com/images/RuvXatuAUGpehzpr83olin2lQ.svg',
      title: 'Heap',
    },
    {
      logo: 'https://framerusercontent.com/images/W77V6qrHGJdyDVvUrVNCSmxMc.png',
      title: 'QuickBooks',
    },
    {
      logo: 'https://framerusercontent.com/images/QUaIcskVRfwVtu3kI47MKTuaMP4.png',
      title: 'Xero',
    },
    {
      logo: 'https://framerusercontent.com/images/qFWZbOmoG6iyTWU1F3IxVEuMh8.svg',
      title: 'Sage Intacct',
    },
    {
      logo: 'https://framerusercontent.com/images/7ZomcrdX319517Ieef0sFbMNBQE.svg',
      title: 'NetSuite',
    },
    {
      logo: 'https://framerusercontent.com/images/0RdEtDTkDSq15SwYafBxapDweko.svg',
      title: 'Dynamics 365 Business Central',
    },
    {
      logo: 'https://framerusercontent.com/images/eM965UD8ZQX6b8GTc2ecKsh2k.png',
      title: 'SAP S/4HANA',
    },
    {
      logo: 'https://framerusercontent.com/images/zHUrVVIB3gj7HbvuKIMw6EmpnL8.svg',
      title: 'Oracle Financials Cloud',
    },
    {
      logo: 'https://framerusercontent.com/images/QCWrNktDJ5Ez9JeG8g8PNgUDkFA.svg',
      title: 'Dynamics 365 Finance',
    },
    {
      logo: 'https://framerusercontent.com/images/BcIYYBYtsd9gDJIl7rxyAznXA2E.svg',
      title: 'Sage Accounting',
    },
    {
      logo: 'https://framerusercontent.com/images/1BsXOShOUku3IrclA7Hfl3XFc.png',
      title: 'Microsoft Dynamics 365 ERP',
    },
    {
      logo: 'https://framerusercontent.com/images/T8osxKeXf0Ja8gAs6jLZUYQo.svg',
      title: 'Harvest',
    },
    {
      logo: 'https://framerusercontent.com/images/JfoHPECGf7KYfXp9xfw9hcYPWL0.png',
      title: 'Google Drive',
    },
    {
      logo: 'https://framerusercontent.com/images/HdiHzVvlvdMka1DC1FdOcW99oA.png',
      title: 'Dropbox',
    },
    {
      logo: 'https://framerusercontent.com/images/RtADmbgHVoXGmiUtFxamC9M3xs.png',
      title: 'Microsoft SharePoint',
    },
    {
      logo: 'https://framerusercontent.com/images/bVZJfjdHgIC5GkxDjqf6thgLw.svg',
      title: 'Box',
    },
    {
      logo: 'https://framerusercontent.com/images/Vk7HDHDwEh7TcGpcf9yGmrOgFY.svg',
      title: 'OneDrive',
    },
    {
      logo: 'https://framerusercontent.com/images/Jger9JpY13qzD9BHetT3zqtNZC8.svg',
      title: 'Shopify',
    },
    {
      logo: 'https://framerusercontent.com/images/wnu3Xd4x7AGa9dHlme882r4f0zQ.png',
      title: 'WooCommerce',
    },
    {
      logo: 'https://framerusercontent.com/images/4Q4wKC9W3owQ4nFwZlvdmVLvlqw.svg',
      title: 'Adobe Commerce',
    },
    {
      logo: 'https://framerusercontent.com/images/v9F26E5pSnFf8Zk3qtLtni9a5pE.png',
      title: 'Magento',
    },
    {
      logo: 'https://framerusercontent.com/images/RFMtZIz8Znl5AQRHLhT3J5wmSLA.png',
      title: 'BigCommerce',
    },
    {
      logo: 'https://framerusercontent.com/images/9wy83Rbp0ZtP86KnuauL43aJ2u4.png',
      title: 'Facebook Ads',
    },
    {
      logo: 'https://framerusercontent.com/images/bMeex2IrLjFXHk2KzrtuwDlvXI.png',
      title: 'Google Campaign Manager',
    },
    {
      logo: 'https://framerusercontent.com/images/WVHW1sAKMWhMYSQidrhAYi4oM.png',
      title: 'Google Ads',
    },
    {
      logo: 'https://framerusercontent.com/images/jq2Jo3tEIFrfZ3cKI0JldYJURs.svg',
      title: 'Google Ad Manager',
    },
    {
      logo: 'https://framerusercontent.com/images/DuLXuJyiu5YMROoFfQa1JcTro8.png',
      title: 'TikTok Ads',
    },
    {
      logo: 'https://framerusercontent.com/images/PwQj309TsIWJeg4dskjemf3Lv4.svg',
      title: 'Adroll',
    },
    {
      logo: 'https://framerusercontent.com/images/s6I4i6rKzMgVuNG4mrgP18mcmY.svg',
      title: 'Instagram Ads',
    },
    {
      logo: 'https://framerusercontent.com/images/nC5P0z9uzq1H01huKwIuRTFmzY.svg',
      title: 'DocuSign',
    },
    {
      logo: 'https://framerusercontent.com/images/vpitWqPFGcjcoYfSTQmTVwDy40.svg',
      title: 'PandaDoc',
    },
    {
      logo: 'https://framerusercontent.com/images/zZcvYq4ngWsmbTdNAVTn2vIqWE.svg',
      title: 'Dropbox Sign',
    },
    {
      logo: 'https://framerusercontent.com/images/k0pOEbKjKEfNBbMefAK2oxtYfk4.png',
      title: 'Adobe Acrobat Sign',
    },
    {
      logo: 'https://framerusercontent.com/images/3iWITYG7RljLKXpYIyEdvgfpe8.png',
      title: 'HelloSign',
    },
    {
      logo: 'https://framerusercontent.com/images/vOq9ykaaOzawZ1iXL8MnwfSFE.svg',
      title: 'Microsoft Teams',
    },
    {
      logo: 'https://framerusercontent.com/images/wNiKc0YcU5YPNJ4VZSRqhfHkzdo.png',
      title: 'Zoom',
    },
    {
      logo: 'https://framerusercontent.com/images/qNaA3d4vbBsgSDbAj99OGffSA.svg',
      title: 'WhatsApp',
    },
    {
      logo: 'https://framerusercontent.com/images/CXSX9Y3T9aWJCGCUFINpbqfn8o.svg',
      title: 'Google Calendar',
    },
    {
      logo: 'https://framerusercontent.com/images/eLDNHqeNJ2P7mMtGbvBlkDteAs.png',
      title: 'Calendly',
    },
    {
      logo: 'https://framerusercontent.com/images/1FFVdynXBn1RKRlWqEwISjsnC7U.png',
      title: 'Miro',
    },
    {
      logo: 'https://framerusercontent.com/images/bN5X23PPyJDkaA6Ak7vSl1cCfE.svg',
      title: 'iManage',
    },
    {
      logo: 'https://framerusercontent.com/images/Nh8YTTfptkCj4C7u1ZNiKueNkI.svg',
      title: 'OpenAi',
    },
    {
      logo: 'https://framerusercontent.com/images/4ip6SsQRtCu98FpZUqbftN419s.jpeg',
      title: 'Excel',
    },
    {
      logo: 'https://framerusercontent.com/images/5Z8v52qhYA5LMbPVOFdRY7GSe9I.png',
      title: 'Figma',
    },
    {
      logo: 'https://framerusercontent.com/images/SPpT2AaEfGWZ86dXjVbFp8VfqBA.png',
      title: 'Stack Overflow for Teams',
    },
    {
      logo: 'https://framerusercontent.com/images/26fbsyQOv4I8LghfIzH75vHfLOo.png',
      title: 'Typeform',
    },
    {
      logo: 'https://framerusercontent.com/images/y7ptb7AWIm10xsMqWWZfr1mnFd8.png',
      title: 'Vanta',
    },
    {
      logo: 'https://framerusercontent.com/images/3A5RwRhbZWCbihlp1KZ2GDq7jUE.svg',
      title: 'Notion',
    },
    {
      logo: 'https://framerusercontent.com/images/SELhA4dHOs9oJnpjNZx2VFsYQW8.png',
      title: 'OneNote',
    },
    {
      logo: 'https://framerusercontent.com/images/sLQLC9OuyxVRdb86x52SwKsT4.png',
      title: 'Confluence',
    },
    {
      logo: 'https://framerusercontent.com/images/eesedkKX1fDFxX4Glo1D1UBFC0.svg',
      title: 'Google Docs',
    },
    {
      logo: 'https://framerusercontent.com/images/qu2ZmCmwOZAoUsJ8x7oRcZmzKi8.png',
      title: 'Coda',
    },
    {
      logo: 'https://framerusercontent.com/images/MUNisVeYmROXEvRNcI6m41qb9Yg.png',
      title: 'Quip',
    },
    {
      logo: 'https://framerusercontent.com/images/eXj6IFWhKP7j9qESY6rX4Tf4yU.svg',
      title: 'JIRA',
    },
    {
      logo: 'https://framerusercontent.com/images/lwC8A4acEtviLVJB1j9c77G2lCs.svg',
      title: 'Asana',
    },
    {
      logo: 'https://framerusercontent.com/images/I1mY8phNFOXGNpL2ci9cn83bGg.png',
      title: 'Trello',
    },
    {
      logo: 'https://framerusercontent.com/images/YVSKNZmSPt71KpNj7NTepe8amD8.png',
      title: 'Monday.com',
    },
    {
      logo: 'https://framerusercontent.com/images/OCi6UWMDrPDifBIxMaXTea7iQ.png',
      title: 'Azure DevOps',
    },
    {
      logo: 'https://framerusercontent.com/images/HA0eotdaNwlrWCeEGy97xtgmKq0.svg',
      title: 'ClickUp',
    },
    {
      logo: 'https://framerusercontent.com/images/bJUJOJXrH7IZJfcIPQzOlEf0PM.png',
      title: 'Linear',
    },
    {
      logo: 'https://framerusercontent.com/images/yRgcVMH8CWyLg0DQ233moyye95o.svg',
      title: 'GitHub',
    },
    {
      logo: 'https://framerusercontent.com/images/JSmj3Dup2WKtAJiDS4BCdIViBg.svg',
      title: 'Airtable',
    },
    {
      logo: 'https://framerusercontent.com/images/yLoGThxJYdehNBfkoZPpIwT5F8.svg',
      title: 'Teamwork',
    },
    {
      logo: 'https://framerusercontent.com/images/3EyXj6JBBwEpt0MjXrN7ox2x7U.svg',
      title: 'Wrike',
    },
    {
      logo: 'https://framerusercontent.com/images/upFSQ4WjCIs6zxZK0Pc6Cr7lUHM.png',
      title: 'Amplitude',
    },
    {
      logo: 'https://framerusercontent.com/images/1M4vA0E1KRV3zUQUv40InJTxQg.svg',
      title: 'Tableau',
    },
    {
      logo: 'https://framerusercontent.com/images/Klu7Sr5Du781bMrgrBxsWXHSkLU.svg',
      title: 'BigQuery',
    },
    {
      logo: 'https://framerusercontent.com/images/uHE7ehHR2SmwYUCGU5LATwNS4.svg',
      title: 'Amazon S3',
    },
    {
      logo: 'https://framerusercontent.com/images/T3mrK5HPMIPyZYAnxEfkT6zo.svg',
      title: 'Power BI',
    },
    {
      logo: 'https://framerusercontent.com/images/hiU0EefZtybYvMBjstfF0ClgR8.svg',
      title: 'Segment',
    },
    {
      logo: 'https://framerusercontent.com/images/pwM3radQOrAed7sSKw8znqFYs.svg',
      title: 'Snowflake',
    },
    {
      logo: 'https://framerusercontent.com/images/eSwQlnqZ4UIWb7APxFMzk9v8E8Q.svg',
      title: 'Vanta',
    },
    {
      logo: 'https://framerusercontent.com/images/nQ9yYfXZP5qiBsB5shw7BQKhg.svg',
      title: 'LinkedIn',
    },
    {
      logo: 'https://framerusercontent.com/images/hFSzktE0u1KVfZlPQOU4QhmXpvw.svg',
      title: 'Twitter',
    },
    {
      logo: 'https://framerusercontent.com/images/F1ArUY7ZuqkGIhoP0FJdkDBF6Es.svg',
      title: 'Facebook Pages',
    },
    {
      logo: 'https://framerusercontent.com/images/3REgt1BoLjdsSIjx17NKidskUtk.svg',
      title: 'Intercom',
    },
    {
      logo: 'https://framerusercontent.com/images/lalO9t01Dw6YQJFqkp2QNvCIF1s.svg',
      title: 'Zendesk',
    },
    {
      logo: 'https://framerusercontent.com/images/9hxi71qGpac9ox2GORrEDNpzdBo.svg',
      title: 'ServiceNow',
    },
    {
      logo: 'https://framerusercontent.com/images/PHhwKUO5zKRjiigIhC4VVV6g3k0.svg',
      title: 'Freshdesk',
    },
    {
      logo: 'https://framerusercontent.com/images/CUBENKHlrSIDkAgAVs12pEjdoA.svg',
      title: 'Gainsight',
    },
    {
      logo: 'https://framerusercontent.com/images/jrTsBgTxKmbLQ5R7o2UtH8zyiXw.png',
      title: 'Front',
    },
    {
      logo: 'https://framerusercontent.com/images/grZ7K2AsWKD5yiA2KFSspU3B1U.png',
      title: 'PagerDuty',
    },
    {
      logo: 'https://framerusercontent.com/images/xkc9xXfn7nSGY5H3mDAWqCeV4.jpeg',
      title: 'Stripe',
    },
    {
      logo: 'https://framerusercontent.com/images/BtHxV71MucujxvT9SKrafrtMtk.svg',
      title: 'Coupa',
    },
    {
      logo: 'https://framerusercontent.com/images/x89M4GAUjs5d7MBmOhsWyrCSiOU.svg',
      title: 'Rippling',
    },
    {
      logo: 'https://framerusercontent.com/images/TgeBNn5yPYYgLBFUTurPk4iTUA.svg',
      title: 'Freshservice',
    },
    {
      logo: 'https://framerusercontent.com/images/CeYzRLejNm7qXPpEB14k6titc.svg',
      title: 'Groove',
    },
    {
      logo: 'https://framerusercontent.com/images/TWlJ0cNG3Uf0N4tX4iuYOTnfCM.svg',
      title: 'Adobe Experience Manager',
    },
    {
      logo: 'https://framerusercontent.com/images/so9cr9kj1nXrBjEKwiuYG8eUKhQ.png',
      title: 'Wordpress',
    },
    {
      logo: 'https://framerusercontent.com/images/C4BS6GXp5Z2D81WbIthjRkLY8r0.svg',
      title: 'Webflow',
    },
    {
      logo: 'https://framerusercontent.com/images/QI3eYQZtsW53c4vHtUjOtddIJE.png',
      title: 'BambooHR',
    },
    {
      logo: 'https://framerusercontent.com/images/KFvs1QZFlV67O7iIym00MSO1GkY.svg',
      title: 'Gusto',
    },
    {
      logo: 'https://framerusercontent.com/images/mVAICqQYnrIl5kINhFCVgVk0Yos.png',
      title: 'Workday',
    },
    {
      logo: 'https://framerusercontent.com/images/CV8HOyX78bKT9C3M3Hfs47texQ.svg',
      title: 'Personio',
    },
    {
      logo: 'https://framerusercontent.com/images/D4h9qsn9uxiDoFqgkrOZF86xc.svg',
      title: 'Lever',
    },
    {
      logo: 'https://framerusercontent.com/images/GDq3lciOm6mRhiiQFqX8kmnKg.svg',
      title: 'Greenhouse',
    },
    {
      logo: 'https://framerusercontent.com/images/qwz24JeitgX9BM8JarQn8LuVIa8.svg',
      title: 'Workable',
    },
    {
      logo: 'https://framerusercontent.com/images/fo5O32DhhbJF4EWkcPcil7YSuV0.svg',
      title: 'Toast',
    },
  ];

  // if (error) {
  //   setToasterMessage({
  //     message:
  //       'Connected services are temporarily not available. Please try again in a few moments.',
  //     intent: Intent.DANGER,
  //   });
  // }

  useEffect(() => {
    if (toasterMessage) {
      AppToaster.then((toaster) => {
        toaster.show({
          message: toasterMessage.message,
          intent: toasterMessage.intent ?? Intent.NONE,
        });
      });
    }
  }, [toasterMessage]);

  return (
    <>
      <div className={styles.buttonContainer}>
        <H2>Your services</H2>
        <ThirdPartyIntegrationProvider>
          <IntegrationList />
        </ThirdPartyIntegrationProvider>
      </div>
      <div className={styles.buttonContainer}>
        <H2>More services</H2>
        <p>
          Contact your Sonans team to activate one or more of these services.
        </p>
        <CardList>
          {allServices.map((service, index) => (
            <Card key={index}>
              <img
                decoding="async"
                src={service.logo}
                width={24}
                alt={service.name}
                style={{ margin: '0 18px' }}
              />
              <div style={{ flex: 1 }}>{service.title}</div>
            </Card>
          ))}
        </CardList>
      </div>
    </>
  );
}
