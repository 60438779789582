export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getCompany = () => window.location.hostname.split('.')[0];
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
export const setAccessToken = (value) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, value);
export const setRefreshToken = (value) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, value);

export const signedHeaders = (obj = {}, token = getAccessToken()) =>
  Object.assign(obj, { Authorization: `Bearer ${token}` });

export const request = (route, options = {}, token = getAccessToken()) => {
  const url = new URL(BASE_URL);
  url.pathname = route;
  options.headers = signedHeaders(options.headers ?? {}, token);
  return fetch(url, options);
};

export const workflowIdFromUrl = () => {
  const workflowInUrl = window.location.pathname.match(
    /^\/workflows\/(\d+)/i,
  );
  return workflowInUrl !== null ? workflowInUrl[1] : null;
};

export const idFromUrl = (slug) => {
  const regex = new RegExp(`^/${slug}/([\\d\\w]+)`);
  const idInUrl = window.location.pathname.match(regex);
  return idInUrl !== null ? idInUrl[1] : null;
};

const memo = { response: undefined, lastChecked: undefined };
const FIVE_MINUTES = 1000 * 60 * 5;
export const getCurrentUser = async () => {
  if (!getAccessToken()) {
    memo.response = undefined;
    memo.lastChecked = undefined;
    return null;
  }

  const inFiveMinutes = memo.lastChecked + FIVE_MINUTES;
  if (typeof memo.lastChecked !== 'undefined' && inFiveMinutes <= new Date()) {
    return memo.response;
  }

  const response = await request('/me');
  if (response.ok) {
    memo.response = await response.json();
    memo.lastChecked = new Date();
    return memo.response;
  }

  return null;
};
