import { paragon } from '@useparagon/connect';
import { createContext, useEffect, useState } from 'react';
import { getAccessToken, getCurrentUser } from '../helpers';
import { getParagonUserToken } from '../helpers/paragon';

export const ThirdPartyIntegrationContext = createContext();

const ENABLED_INTEGRATIONS = [
  {
    name: 'gmail',
    title: 'Gmail',
    enabled: false,
  },
  {
    name: 'googledrive',
    title: 'Google Drive',
    enabled: false,
  },
  {
    name: 'googleCalendar',
    title: 'Google Calendar',
    enabled: false,
  },
  {
    name: 'googlesheets',
    title: 'Google Sheets',
    enabled: false,
  },
  {
    name: 'outlook',
    title: 'Microsoft Outlook',
    enabled: false,
  },
  {
    name: 'mailchimp',
    title: 'Mailchimp',
    enabled: false,
  },
  {
    name: 'slack',
    title: 'Slack',
    enabled: false,
  },
];

class SearchResult {
  constructor(id, name, target, displayName) {
    this.id = id;
    this.name = name;
    this.target = target;
    this.displayName = displayName;
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const googleDriveFolders = async (paragonToken) => {
  const folders = [];

  let pageToken = null;

  while (true) {
    const q = new URLSearchParams();
    q.set('q', `mimeType = 'application/vnd.google-apps.folder'`);

    if (pageToken) {
      q.set('pageToken', pageToken);
    }

    try {
      const response = await paragon.request('googledrive', `/files?${q}`, {
        method: 'GET',
      });

      pageToken = response.nextPageToken;

      if (response?.files) {
        const filteredFolders = response.files.map(
          (item) =>
            new SearchResult(item.id, item.name, 'googledrive', 'Google Drive'),
        );
        folders.push(...filteredFolders);
      }

      if (!pageToken) {
        break;
      }
    } catch (err) {
      console.log(err);
      break;
    }
  }

  return folders;
};

const googleSheetsFiles = async (paragonToken) => {
  const folders = [];

  let pageToken = null;

  while (true) {
    const q = new URLSearchParams();
    q.set('q', `mimeType = 'application/vnd.google-apps.spreadsheet'`);

    if (pageToken) {
      q.set('pageToken', pageToken);
    }

    try {
      const response = await paragon.request('googledrive', `/files?${q}`, {
        method: 'GET',
      });

      pageToken = response.nextPageToken;

      if (response?.files) {
        const filteredFolders = response.files.map(
          (item) =>
            new SearchResult(
              item.id,
              item.name,
              'googlesheets',
              'Google Sheets',
            ),
        );
        folders.push(...filteredFolders);
      }

      if (!pageToken) {
        break;
      }
    } catch (err) {
      console.log(err);
      break;
    }
  }

  return folders;
};

const slackRequest = async (slackEndpoint, paragonToken) => {
  const endpoint = `https://api.useparagon.com/projects/${process.env.REACT_APP_PARAGON_PROJECT_ID}/sdk/proxy/slack${slackEndpoint}`;

  let result;

  try {
    result = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${paragonToken}`,
        'Content-Type': 'application/json',
      },
    });

    result = await result.json();

    if (result.status === 429) {
      const retryAfter = result.headers['retry-after'];

      console.info('Rate limited, retrying after', retryAfter, 'seconds');

      await sleep(retryAfter * 1000);
      return slackRequest(slackEndpoint, paragonToken);
    }

    result = result.output;
  } catch (err) {
    console.log('Error', err);
  }

  return result;
};

const mailchimpLists = async (paragonToken) => {
  const endpoint = `https://api.useparagon.com/projects/${process.env.REACT_APP_PARAGON_PROJECT_ID}/sdk/proxy/mailchimp/lists`;

  try {
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${paragonToken}`,
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    if (!result.output?.lists) {
      return [];
    }

    return result.output.lists.map(
      (list) => new SearchResult(list.id, list.name, 'mailchimp', 'Mailchimp'),
    );
  } catch (err) {
    console.log('Error', err);
    return [];
  }
};

const slackChannels = (paragonToken) => {
  return slackRequest('/conversations.list', paragonToken);
};

const slackUsers = (paragonToken) => {
  return slackRequest('/users.list', paragonToken);
};

const slackContacts = async (paragonToken) => {
  const contacts = [];

  let channels = [];
  let members = [];

  try {
    let response = await slackChannels(paragonToken);
    channels = response.channels;

    await sleep(1000);

    response = await slackUsers(paragonToken);

    members = response.members;
  } catch (err) {
    console.log(err);
  }

  [...channels, ...members].forEach((contact) => {
    const id = contact.id;
    const name = contact.is_channel ? `#${contact.name}` : contact.name;
    contacts.push(new SearchResult(id, name, 'slack', 'Slack'));
  });

  return contacts;
};

const gmailTrigger = async () => {
  const user = await getCurrentUser();
  return [
    new SearchResult(
      user.username,
      'I receive a Gmail message',
      'gmail',
      'Gmail',
    ),
  ];
};

const outlookTrigger = async (paragonToken) => {
  const endpoint = `https://api.useparagon.com/projects/${process.env.REACT_APP_PARAGON_PROJECT_ID}/sdk/proxy/outlook/me`;

  try {
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${paragonToken}`,
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();

    if (!result) {
      return [];
    }

    return [
      new SearchResult(
        result.output.mail,
        'I receive an Outlook message',
        'outlook',
        'Outlook',
      ),
    ];
  } catch (err) {
    console.log('Error', err);
    return [];
  }
};

const providers = {
  googledrive: googleDriveFolders,
  googlesheets: googleSheetsFiles,
  mailchimp: mailchimpLists,
  slack: slackContacts,
  gmail: gmailTrigger,
  outlook: outlookTrigger,
};

const getUserEnabledIntegrations = (integrations) => {
  const paragonEnabledIntegrations = Object.keys(integrations).filter(
    (integration) => {
      return integrations[integration].enabled;
    },
  );

  const userActiveIntegrations = ENABLED_INTEGRATIONS.map((integration) => {
    return {
      ...integration,
      enabled: paragonEnabledIntegrations.includes(integration.name),
    };
  });

  return userActiveIntegrations;
};

export function ThirdPartyIntegrationProvider({ children }) {
  const [integrations, setIntegrations] = useState(ENABLED_INTEGRATIONS);
  const [token, setToken] = useState(null);
  const [error, setError] = useState();
  const [sonansUser, setSonansUser] = useState();
  const [isParagonAuth, setIsParagonAuth] = useState(false);
  const [providersData, setProvidersData] = useState([]);

  const fetchProvidersData = () => {
    const userEnabledIntegrations = integrations.filter((integration) => {
      return integration.enabled;
    });

    const callbacks = userEnabledIntegrations.map((integration) => {
      const { name } = integration;
      if (providers.hasOwnProperty(name)) {
        return providers[name](token);
      }

      return Promise.resolve([]);
    });

    Promise.all(callbacks).then((results) => {
      setProvidersData(results.flat());
    });
  };

  const refreshEnabledIntegrations = () => {
    const paragonUser = paragon.getUser();
    const userActiveIntegrations = getUserEnabledIntegrations(
      paragonUser.integrations,
    );
    setIntegrations(userActiveIntegrations);
  };

  useEffect(() => {
    getParagonUserToken().then(setToken).catch(setError);
    getCurrentUser().then(setSonansUser).catch(setError);
  }, []);

  useEffect(() => {
    if (sonansUser && token) {
      paragon
        .authenticate(process.env.REACT_APP_PARAGON_PROJECT_ID, token, {
          metadata: {
            Name: sonansUser.name,
            slug: sonansUser.slug,
            accessToken: getAccessToken(),
            Email: sonansUser.username,
          },
        })
        .then(() => {
          setIsParagonAuth(true);
          refreshEnabledIntegrations();
        });
    }
  }, [sonansUser, token]);

  useEffect(() => {
    if (isParagonAuth) {
      fetchProvidersData();
    }
  }, [integrations, isParagonAuth]);

  const connect = (integrationType) => {
    const connectOptions = {};

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      connectOptions['overrideRedirectUrl'] =
        `${process.env.REACT_APP_BASE_URL}/integrations/oauth`;
    }

    paragon
      .installIntegration(integrationType, connectOptions)
      .then(() => refreshEnabledIntegrations())
      .catch(() => {
        // TODO Display error if the integration is already installed
      });
  };

  const disconnect = (integrationType) => {
    paragon.uninstallIntegration(integrationType).then(() => {
      refreshEnabledIntegrations();
    });
  };

  return (
    <ThirdPartyIntegrationContext.Provider
      value={{
        integrations,
        providersData,
        fetchProvidersData,
        connect,
        disconnect,
        isParagonAuth,
      }}
    >
      {children}
    </ThirdPartyIntegrationContext.Provider>
  );
}
