import React, { useEffect, useRef, useState } from 'react';
import AppLogo from './components/AppLogo';
import { Link } from 'react-router-dom';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import styles from './ForgotPasswordView.module.css';
import { request } from './helpers';
import { AppToaster } from './AppToaster';

export default function ForgotPasswordView() {
  const emailRef = useRef();
  const [isFormPending, setIsFormPending] = useState(false);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (toasterMessage) {
      AppToaster.then((toaster) => {
        toaster.show({
          message: toasterMessage.message,
          intent: toasterMessage.intent,
        });
      });
    }
  }, [toasterMessage]);

  const handleButtonClick = async () => {
    setIsFormPending(true);

    const bodyPayload = { email: emailRef.current.value };

    let intent = Intent.SUCCESS;
    let message;

    try {
      const response = await request('/forgot-password', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(bodyPayload),
      });

      if (!response.ok) {
        intent = Intent.DANGER;
      }

      const data = await response.json();
      message = data.message;
    } catch (err) {
      message = 'Something went wrong';
      intent = Intent.DANGER;
    }

    setToasterMessage({
      message,
      intent,
    });

    setIsFormPending(false);
  };

  const handleEnterKey = async (e) => {
    if (e.key === 'Enter' && !disabled && !isFormPending) {
      await handleButtonClick();
    }
  };

  const checkInput = (e) => setDisabled(!emailRef.current?.value);

  return (
    <section className={styles.container}>
      <div className={styles.headingSection}>
        <AppLogo />
        <h2>Forgot password</h2>
      </div>

      <FormGroup label="Email address" labelFor="email-address" fill>
        <InputGroup
          id="email-address"
          placeholder="example@email.com"
          inputRef={emailRef}
          type="text"
          onChange={checkInput}
          onKeyDown={handleEnterKey}
          large={true}
        />
      </FormGroup>

      <Button
        type="submit"
        disabled={disabled || isFormPending}
        size="large"
        text="Reset password"
        loading={isFormPending}
        onClick={handleButtonClick}
        fill={true}
        className={styles.resetPasswordButton}
      />

      <p className={styles.loginLink}>
        Changed your mind? <Link to="/login">Login</Link>
      </p>
    </section>
  );
}
