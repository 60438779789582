import { createContext, useContext, useState } from 'react';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  getCompany,
  getCurrentUser,
  getRefreshToken,
  request,
  setAccessToken,
  setRefreshToken,
} from './helpers';
import { authenticateParagon } from './helpers/paragon';

const authContext = createContext();
let interval = null;

export const refreshToken = async () =>
  await request(
    `/refresh`,
    {
      method: 'POST',
    },
    getRefreshToken(),
  ).then((response) => {
    if (!response.ok) {
      throw new Error('Response is not ok');
    }
    return response.json();
  });

function useProvideAuth(initialState = undefined, userModel = undefined) {
  const [authed, setAuthed] = useState(initialState);
  const [user, setUser] = useState(userModel);

  return {
    authed,
    user,
    login(username, password) {
      return new Promise((res, rej) => {
        request('/login', {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            username,
            password,
            company: getCompany(),
          }),
        })
          .then((response) => {
            if (response.status !== 200 && response.status !== 401) {
              return rej(
                `We cannot sign you in right now. Please try again in a moment (HTTP status: ${response.status})`,
              );
            }

            try {
              return response.json();
            } catch (e) {
              rej(
                'We cannot sign you in right now. Please try again in a moment.',
              );
            }
          })
          .then((body) => {
            if (body.type === 'error') {
              return rej(body.message);
            }

            setAccessToken(body.access_token);
            setRefreshToken(body.refresh_token);
            authenticateParagon();
            if (!interval) {
              interval = setInterval(
                () => {
                  refreshToken()
                    .then(({ access_token }) => {
                      setAccessToken(access_token);
                      authenticateParagon();
                    })
                    .catch((e) => {
                      console.error(
                        'Error while refreshing the token:',
                        e.message,
                      );
                      setAuthed(false);
                      setUser(null);
                    });

                  getCurrentUser()
                    .then((res) => {
                      if (!res) {
                        localStorage.removeItem(ACCESS_TOKEN_KEY);
                        localStorage.removeItem(REFRESH_TOKEN_KEY);
                      } else {
                        setUser(res);
                        setAuthed(true);
                      }
                    })
                    .catch((e) => {
                      console.error(
                        'Error while refreshing current user:',
                        e.message,
                      );
                      setUser(null);
                      setAuthed(false);
                    });
                },
                1000 * 60 * 5,
              );
            }
            setAuthed(true);
            res(true);
          });
      });
    },
    logout() {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      setAuthed(false);
      setUser(undefined);
    },
  };
}

export function AuthProvider({ children, initialState, userModel }) {
  const auth = useProvideAuth(initialState, userModel);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}
