class Model {
  constructor(attributes) {
    const allowedFields = Object.keys(this.fields);
    if (attributes) {
      for (const key of allowedFields) {
        this[key] = attributes.hasOwnProperty(key) ? attributes[key] : this.fields[key];
      }
    }
  }

  get fields() {
    return {};
  }
}
export class Megaprompt extends Model {
  get fields() {
    return {
      id: null,
      name: '',
      prompt: '',
    };
  }
}

export class TaskAssistant extends Model {
  get fields() {
    return {
      id: null,
      name: '',
      description: '',
      instructions: '',
      requires_file: false,
      tools: [],
      is_default: false,
    };
  }

  constructor(data) {
    super(data)
    if (data.tools && Array.isArray(data.tools)) {
      this.tools = data.tools.map((item) => new TaskAssistant(item))
    }
  }
}
