import {
  Classes,
  EntityTitle,
  H4,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppToaster } from './AppToaster';
import WorkflowSelector from './components/Workflow/WorkflowSelector';
import { request, workflowIdFromUrl } from './helpers';
import { Megaprompt } from './models';

function WorkflowItem({
  className,
  text,
  intent,
  onWorkflowSelected,
  threadId,
}) {
  const handleClick = (e) => {
    if (onWorkflowSelected && typeof onWorkflowSelected === 'function') {
      onWorkflowSelected(threadId);
    }
  };

  const [hover] = useState(false);

  const archiveIcon = (
    <Tooltip content="Archive">
      <Icon icon="archive" />
    </Tooltip>
  );

  return (
    <MenuItem
      className={className}
      text={text}
      intent={intent}
      labelElement={hover ? archiveIcon : null}
      // onMouseOver={() => setHover(true)}
      // onMouseOut={() => setHover(false)}
      onClick={handleClick}
    />
  );
}

function Logo() {
  return (
    <div className="logo-container" style={{ display: 'flex' }}>
      <div>
        <img alt="Logo" src="/logo192-clear.png" />
      </div>
      <div style={{ flex: 1 }}>
        <EntityTitle
          heading={H4}
          className="bp5-dark app-title"
          title="Sonans"
          tags={
            <Tooltip
              intent={Intent.WARNING}
              content={
                <div style={{ maxWidth: 200 }}>
                  <p>
                    This is an early pre-release version made available to our
                    pilot partners for testing and feedback purposes. Some
                    functionality may not be implemented yet, and you may
                    encouter bugs and any issues as you use this pre-release
                    version.
                  </p>
                  <p>
                    Please report bugs or suggestions for improvement to your
                    Sonans contact.
                  </p>
                </div>
              }
            >
              <Tag intent={Intent.DANGER} minimal={true}>
                Pre-release
              </Tag>
            </Tooltip>
          }
        />
      </div>
    </div>
  );
}

export default function ThreadList({
  onWorkflowSelected = () => {},
  onWorkflowCountUpdated = () => {},
}) {
  const [toasterMessage, setToasterMessage] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [workflowId, setWorkflowId] = useState(workflowIdFromUrl());
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (toasterMessage) {
      AppToaster.then((toaster) => {
        toaster.show({
          message: toasterMessage.message,
          intent: toasterMessage.intent,
        });
      });
    }
  }, [toasterMessage]);

  const fetchWorkflows = () => {
    setIsLoading(true);
    request('/megaprompts')
      .then((response) => response.json())
      .then(({ data }) => {
        setIsLoading(false);
        const result = data.map((item) => new Megaprompt(item));
        setWorkflows(result);

        onWorkflowCountUpdated(data.length);
      })
      .catch((e) => {
        console.error(e);
        setToasterMessage({
          message:
            'Error while retrieving the workflow list. Please refresh the page and try again.',
          intent: Intent.DANGER,
        });
        setIsLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    fetchWorkflows();
    fetchWorkflows();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeWorkflow = (newWorkflowId) => {
    if (newWorkflowId === workflowId) {
      return;
    }

    navigate(`/workflows/${newWorkflowId}`);
    const oldThreadId = workflowId;

    setWorkflowId(newWorkflowId);
    if (newWorkflowId !== oldThreadId) {
      onWorkflowSelected(newWorkflowId);
    }
  };

  const renderSkeleton = () =>
    Array(24)
      .fill('Workflow')
      .map((name, i) => (
        <div
          key={i}
          className={Classes.SKELETON}
          style={{
            margin: '0.4rem',
            padding: '0.4rem',
            width: 120 + Math.random() * 128,
          }}
        >{`${name} ${i + 1}`}</div>
      ));

  const renderMenu = () => (
    <Menu>
      <MenuDivider title="Your workflows" />
      {workflows.map(({ id, name }, i) => (
        <WorkflowItem
          key={i}
          className={id === workflowId ? 'list-item selected' : 'list-item'}
          intent={id === workflowId ? Intent.PRIMARY : Intent.NONE}
          active={id === workflowId}
          text={name}
          threadId={id}
          onWorkflowSelected={changeWorkflow}
        />
      ))}
    </Menu>
  );

  return (
    <div className="thread-list-container bp5-dark">
      <Logo />
      {isLoading ? renderSkeleton() : renderMenu()}
    </div>
  );
}
