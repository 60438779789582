import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import { useEffect } from "react";

export default function Home() {
  const { authed } = useAuth();
  console.log('[/] authed', authed)
  const navigate = useNavigate()

  useEffect(() => {
    navigate(authed ? '/workflows' : '/login', {
      replace: true,
      state: { path: window.location.pathname },
    });      
  }, [authed, navigate]);

  return <></>
}