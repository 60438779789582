import { Button } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './WorkflowEditorView.css';
import WorkflowBuilder from './components/Workflow/WorkflowBuilder';
import WorkflowSelector from './components/Workflow/WorkflowSelector';
import { request } from './helpers';
import { Megaprompt } from './models';

export default function WorkflowEditorView() {
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const { workflowId } = useParams();

  const saveMegaprompt = async (megaprompt) => {
    const [url, method] =
      megaprompt.id === null || megaprompt.id === 'new'
        ? [`/megaprompts`, 'POST']
        : [`/megaprompts/${megaprompt.id}`, 'PATCH'];

    await request(url, {
      method,
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(megaprompt),
    });

    // TODO handle error if request fails
    navigate('/workflows/edit');
  };

  const deleteMegaprompt = async (megaprompt) => {
    await request(`/megaprompts/${megaprompt.id}`, {
      method: 'DELETE',
    });

    navigate('/workflows/edit');
  };

  useEffect(() => {
    request('/megaprompts')
      .then((response) => response.json())
      .then(({ data }) => {
        const result = data.map((item) => new Megaprompt(item));
        setWorkflows(result);
      });
  }, [location]);

  useEffect(() => {
    if (workflowId) {
      const wf = workflows.find((item) => item.id === +workflowId) || {};
      setWorkflow(workflowId === 'new' ? {} : wf);
    }
  }, [location, workflows, workflowId]);

  return (
    <div>
      <div className="task-list-container">
        <Button
          style={{ marginTop: 10 }}
          minimal
          icon="arrow-left"
          onClick={() => navigate('/workflows')}
          text="Workflows"
        />
        <h1>Edit workflows</h1>
        <WorkflowSelector
          workflows={workflows}
          onWorkflowSelected={(workflow) =>
            navigate(`/workflows/edit/${workflow.id}`)
          }
        />
        <div style={{ marginBottom: 30 }}>
          <Button
            minimal
            icon="add"
            onClick={() => navigate('/workflows/edit/new')}
          >
            Create new workflow
          </Button>
        </div>
        <WorkflowBuilder
          workflow={workflow}
          onSave={saveMegaprompt}
          onClose={() => navigate('/workflows/edit')}
          onDelete={deleteMegaprompt}
        />
      </div>
    </div>
  );
}
