import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './useAuth';

export default function RequireAuth({ children, superRequired = false }) {
  const { authed, user } = useAuth();
  const location = useLocation();

  if (superRequired) {
    return authed === true && user.super === true ? (
      children
    ) : (
      <Navigate to="/404" replace state={{ path: location.pathname }} />
    );
  } else {
    return authed === true ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
  }
}