import {
  Button,
  Callout,
  EntityTitle,
  FormGroup,
  H1,
  InputGroup,
  Intent,
  Tooltip,
} from '@blueprintjs/core';
import React, { useEffect, useRef, useState } from 'react';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import './Login.css';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const handleLockClick = () => setShowPassword(!showPassword);
  const [disabled, setDisabled] = useState(true);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const { authed, login } = useAuth();

  const usernameRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    setTimeout(usernameRef.current?.focus(), 100);
  }, []);

  const checkInput = (e) =>
    setDisabled(!usernameRef.current?.value || !passwordRef.current?.value);

  const handleRequest = async () => {
    setRequestIsLoading(true);
    setErrorMessage('');
    try {
      await login(usernameRef.current?.value, passwordRef.current?.value);
      navigate(state && state.path !== '/login' ? state.path : '/workflows');
    } catch (e) {
      console.log(e);
      setErrorMessage(e);
    } finally {
      setRequestIsLoading(false);
    }
  };

  const lockButton = (
    <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
      <Button
        icon={showPassword ? 'unlock' : 'lock'}
        intent={Intent.WARNING}
        minimal={true}
        onClick={handleLockClick}
      />
    </Tooltip>
  );

  const handleEnterKey = async (e) => {
    if (e.key === 'Enter' && !disabled && !requestIsLoading) {
      await handleRequest();
    }
  };

  if (authed) {
    return (
      <Navigate
        to="/workflows"
        replace
        state={{ path: window.location.pathname }}
      />
    );
  }

  return (
    <div className="login-container">
      <div className="logo-container" style={{ display: 'flex' }}>
        <div>
          <img alt="Logo" src="/logo192.png" />
        </div>
        <div style={{ flex: 1 }}>
          <EntityTitle heading={H1} className="app-title" title="Sonans" />
        </div>
      </div>
      {errorMessage ? (
        <Callout title={errorMessage} intent={Intent.DANGER} icon="info-sign" />
      ) : (
        <></>
      )}
      <FormGroup>
        <InputGroup
          inputRef={usernameRef}
          disabled={requestIsLoading}
          className="input"
          large={true}
          placeholder="Username"
          type="text"
          onChange={checkInput}
          onKeyDown={handleEnterKey}
        />

        <InputGroup
          inputRef={passwordRef}
          disabled={requestIsLoading}
          className="input"
          large={true}
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          rightElement={lockButton}
          onChange={checkInput}
          onKeyDown={handleEnterKey}
        />
        <Button
          type="submit"
          disabled={disabled || requestIsLoading}
          size="large"
          text="Login"
          onClick={handleRequest}
          fill={true}
        />
      </FormGroup>
      <div>
        <Link to="/forgot-password">Forgot password?</Link>
      </div>
    </div>
  );
}
