import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './helpers';
import { useAuth } from './useAuth';
import Internal from './Internal';

export default function UserChip() {
  const [user, setUser] = useState({ name: 'User' });
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  useEffect(() => {
    getCurrentUser().then((data) =>
      data !== null ? setUser(data) : setUser({ name: 'User' }),
    );
  }, []);

  return (
    <Popover
      fill={true}
      position="top"
      modifiers={{ arrow: { enabled: false } }}
      minimal={true}
      content={
        <Menu key="menu">
          <MenuDivider title={`${user.name} (${user.company})`} />
          <MenuItem
            text="Edit workflows"
            onClick={() => navigate('/workflows/edit')}
          />
          {user.admin ? 
            <MenuItem
              text="Manage integrations"
              onClick={() => navigate('/third-party-integration')}
            /> : <></>}
          <Internal>
            <MenuDivider title="Internal" />
            <MenuItem
              text="System settings"
              onClick={() => navigate('/config')}
            />
          </Internal>
          <MenuDivider />
          <MenuItem text="Sign out" onClick={handleLogout} />
        </Menu>
      }
    >
      <Button
        style={{ outline: 'none' }}
        fill={true}
        text={user.name}
        minimal={true}
        large={true}
        alignText="left"
        ellipsizeText={true}
        icon="person"
      />
    </Popover>
  );
}
