import {
  Button,
  Classes,
  Collapse,
  EditableText,
  H4,
  Intent,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Megaprompt } from '../../models';
import Internal from '../Internal';

import { ThirdPartyIntegrationProvider } from '../../contexts/ThirdPartyIntegrationContext';
import WorkflowEditor from './WorkflowEditor';

import {
  extractMentions,
  generateInstructions,
} from '../../helpers/workflowBuilder';

export default function WorkflowBuilder({
  workflow = {},
  onSave = () => {},
  onClose = () => {},
  onDelete = () => {},
}) {
  const { workflowId } = useParams();

  const [name, setName] = useState();
  const [prompt, setPrompt] = useState({});

  const [editorInputInitialContent, setEditorInputInitialContent] = useState();
  const [
    editorInstructionsInitialContent,
    setEditorInstructionsInitialContent,
  ] = useState();

  const [internalExpanded, setInternalExpanded] = useState(false);

  useEffect(() => {
    let promptPayload = {};
    let initialPromptPayload = {
      uri: '.sonans',
      input: [],
      instructions: null,
      sonans_ui_label: 'This workflow is automated',
      tool_denylist: '',
      editor_input: [{}],
      editor_instructions: [{}],
    };

    try {
      promptPayload = JSON.parse(workflow.prompt);
      promptPayload = { ...initialPromptPayload, ...promptPayload };
    } catch (e) {
      promptPayload = initialPromptPayload;
    }

    setName(workflow?.name ?? '');
    setPrompt(promptPayload);

    setEditorInputInitialContent(promptPayload.editor_input);
    setEditorInstructionsInitialContent(promptPayload.editor_instructions);
  }, [workflow]);

  const handleSave = () => {
    const object = workflow ?? new Megaprompt({});
    object.id = workflowId;
    object.name = name;
    object.prompt = JSON.stringify(prompt);

    onSave(object);
  };

  // TODO Double check if this is the correct way to handle delete
  const handleDelete = () => {
    if (window.confirm('Are you sure?')) {
      const object = workflow ?? new Megaprompt({});
      object.id = workflowId;
      object.name = name;
      object.prompt = JSON.stringify(prompt);
      onDelete(object);
    }
  };

  const handleInputChange = (content) => {
    setPrompt((prevState) => ({
      ...prevState,
      editor_input: content,
      input: extractMentions(content),
    }));
  };

  const handleInstructionsChange = (content) => {
    setPrompt((prevState) => ({
      ...prevState,
      editor_instructions: content,
      instructions: generateInstructions(content),
    }));
  };

  if (!workflowId) {
    return <></>;
  }

  return (
    <ThirdPartyIntegrationProvider>
      <Section
        title={
          <EditableText
            placeholder="Workflow title"
            value={name}
            onChange={(value) => setName(value)}
          />
        }
      >
        <SectionCard>
          <H4>Input</H4>
          <WorkflowEditor
            initialContent={editorInputInitialContent}
            onChange={handleInputChange}
            // userIntegrations={userIntegrations}
          />

          <H4>Steps</H4>
          <WorkflowEditor
            initialContent={editorInstructionsInitialContent}
            onChange={handleInstructionsChange}
            // userIntegrations={userIntegrations}
          />
        </SectionCard>

        <SectionCard>
          <Internal>
            <Button
              minimal
              icon={internalExpanded ? 'chevron-down' : 'chevron-right'}
              onClick={() => setInternalExpanded(!internalExpanded)}
            >
              Internal
            </Button>
            <Collapse isOpen={internalExpanded}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ flex: 1, lineHeight: '3rem' }}
                  className={Classes.TEXT_MUTED}
                >
                  Configuration name
                </div>
                <div style={{ lineHeight: '3rem' }}>
                  <input
                    type="text"
                    value={prompt.uri || '.sonans'}
                    onChange={(e) =>
                      setPrompt((prevState) => ({
                        ...prevState,
                        uri: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ flex: 1, lineHeight: '3rem' }}
                  className={Classes.TEXT_MUTED}
                >
                  Tool denylist (comma-separated)
                </div>
                <div style={{ lineHeight: '3rem' }}>
                  <input
                    type="text"
                    value={prompt.tool_denylist || ''}
                    onChange={(e) =>
                      setPrompt((prevState) => ({
                        ...prevState,
                        tool_denylist: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ flex: 1, lineHeight: '3rem' }}
                  className={Classes.TEXT_MUTED}
                >
                  Instructions for Sonans UI
                </div>
                <div style={{ lineHeight: '3rem' }}>
                  <textarea
                    type="text"
                    value={prompt.sonans_ui_label}
                    onChange={(e) =>
                      setPrompt((prevState) => ({
                        ...prevState,
                        sonans_ui_label: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </Collapse>
          </Internal>
        </SectionCard>

        <SectionCard>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {workflowId !== 'new' ? (
                <Button
                  style={{ margin: '0 0.25rem' }}
                  intent={Intent.DANGER}
                  text="Delete"
                  onClick={handleDelete}
                />
              ) : (
                <></>
              )}
            </div>
            <div>
              <Button
                style={{ margin: '0 0.25rem' }}
                intent={Intent.NONE}
                text="Close"
                onClick={onClose}
              />
              <Button
                style={{ margin: '0 0.25rem' }}
                intent={Intent.PRIMARY}
                text="Save"
                onClick={handleSave}
              />
            </div>
          </div>
        </SectionCard>
      </Section>
    </ThirdPartyIntegrationProvider>
  );
}
