import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ConfigView from './ConfigView';
import ForgotPasswordView from './ForgotPasswordView';
import Home from './Home';
import Login from './Login';
import NotFound from './NotFound';
import RequireAuth from './RequireAuth';
import ResetPasswordView from './ResetPasswordView';
import WorkflowEditorView from './WorkflowEditorView';
import WorkflowView from './WorkflowView';
import { getCurrentUser, getRefreshToken, setAccessToken } from './helpers';
import ThirdPartyIntegrationPage from './pages/ThirdPartyIntegrationPage';
import { AuthProvider, refreshToken } from './useAuth';

let isLoggedIn = undefined;
let user = undefined;
if (getRefreshToken()) {
  try {
    const response = await refreshToken();
    const { access_token } = response;
    setAccessToken(access_token);
    isLoggedIn = true;
    user = await getCurrentUser();
  } catch (e) {
    isLoggedIn = false;
    user = undefined;
  }
}

export default function App() {
  return (
    <AuthProvider initialState={isLoggedIn} userModel={user}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            path="/workflows/:threadId?"
            element={
              <RequireAuth>
                <WorkflowView />
              </RequireAuth>
            }
          />
          <Route
            path="/workflows/edit/:workflowId?"
            element={
              <RequireAuth>
                <WorkflowEditorView />
              </RequireAuth>
            }
          />
          <Route
            path="/third-party-integration"
            element={
              <RequireAuth>
                <ThirdPartyIntegrationPage />
              </RequireAuth>
            }
          />
          <Route
            path="/config"
            element={
              <RequireAuth superRequired={true}>
                <ConfigView />
              </RequireAuth>
            }
          />
          <Route path="/reset-password" element={<ResetPasswordView />} />
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
