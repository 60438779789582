import { Button, Intent, Spinner } from '@blueprintjs/core';
import { useContext } from 'react';
import { ThirdPartyIntegrationContext } from '../../contexts/ThirdPartyIntegrationContext';

export default function IntegrationConnectButton({
  integrationType,
  isEnabled,
}) {
  const { connect, disconnect, isParagonAuth } = useContext(
    ThirdPartyIntegrationContext,
  );

  if (!isParagonAuth) {
    return <Spinner size={20} />;
  }

  const connectIntegration = () => {
    connect(integrationType);
  };

  const disconnectIntegration = () => {
    disconnect(integrationType);
  };

  if (!isEnabled) {
    return (
      <Button
        variant="contained"
        onClick={connectIntegration}
        intent={Intent.PRIMARY}
      >
        Connect
      </Button>
    );
  } else {
    return (
      <Button onClick={disconnectIntegration} intent={Intent.DANGER} outlined>
        Disconnect
      </Button>
    );
  }
}
