import {
  Alert,
  Button,
  Callout,
  Card,
  CardList,
  Checkbox,
  H4,
  Intent,
  NonIdealState,
  Spinner,
  Tab,
  Tabs,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import React, { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useLocation, useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { AppToaster } from './AppToaster';
import UserChip from './UserChip';
import WorkflowList from './WorkflowList';
import './WorkflowView.css';
import { request, workflowIdFromUrl } from './helpers';
import { useAuth } from './useAuth';

const ExecutionLogEntry = ({
  heading = false,
  thread,
  status,
  started_at,
  completed_at = null,
  user,
}) => {
  const style = { flex: 1, fontWeight: heading ? 'bold' : 'normal' };
  let intent = Intent.NONE;
  switch (status) {
    case 'started':
      intent = Intent.PRIMARY;
      break;
    case 'failed':
      intent = Intent.DANGER;
      break;
    case 'completed':
      intent = Intent.SUCCESS;
      break;
    default:
      break;
  }

  const startedDate = started_at ? new Date(started_at).toLocaleString() : '';
  const completedDate = completed_at
    ? new Date(completed_at).toLocaleString()
    : '';

  return (
    <Card>
      <div style={style}>
        {heading ? (
          status
        ) : (
          <Tag minimal intent={intent}>
            {status.toUpperCase()}
          </Tag>
        )}
      </div>
      <div style={style}>{heading ? started_at : startedDate}</div>
      <div style={style}>{heading ? completed_at : completedDate}</div>
      <div style={style}>{user.name}</div>
      <div style={{ flex: 2, fontWeight: heading ? 'bold' : 'normal' }}>
        {thread.id}
      </div>
    </Card>
  );
};
export default function WorkflowView() {
  const [workflowId, setWorkflowId] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [workflowCount, setWorkflowCount] = useState(-1);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [showWorkflowRunsWarning, setShowWorkflowRunsWarning] = useState(
    window.localStorage.getItem('showWorkflowRunsWarning') === null,
  );
  const [workflow, setWorkflow] = useState(null);
  const [workflowRuns, setWorkflowRuns] = useState([]);
  const [showPrereleaseAlert, setShowPrereleaseAlert] = useState(
    window.localStorage.getItem('skipPrereleaseAlert') === null ||
      window.localStorage.getItem('skipPrereleaseAlert') === 'false',
  );
  const [skipPrereleaseAlert, setSkipPrereleaseAlert] = useState(false);
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const interval = useRef(null);

  useEffect(() => {
    if (workflowId !== '') {
      interval.current = setInterval(() => {
        fetchWorkflowRuns(workflowId);
      }, 2000);
    }

    return () => clearInterval(interval.current);
  }, [workflowId]);

  useEffect(() => {
    if (toasterMessage) {
      AppToaster.then((toaster) => {
        toaster.show({
          message: toasterMessage.message,
          intent: toasterMessage.intent,
        });
      });
    }
  }, [toasterMessage]);

  const handleConfirmPrerelease = () => {
    setShowPrereleaseAlert(false);
    window.localStorage.setItem(
      'skipPrereleaseAlert',
      skipPrereleaseAlert ? 'true' : 'false',
    );
  };

  const fetchWorkflow = (megapromptId) => {
    setIsWaiting(true);
    setForbidden(false);
    setWorkflowId(megapromptId);
    request(`/megaprompts/${megapromptId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setForbidden(true);
          return { data: {} };
        }
      })
      .then(({ data }) => {
        setWorkflow(JSON.parse(data.prompt));
      })
      .finally(() => setIsWaiting(false));
  };

  const fetchWorkflowRuns = (megapromptId) => {
    setWorkflowId(megapromptId);
    request(`/megaprompts/${megapromptId}/runs`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setWorkflowRuns([]);
        }
      })
      .then(({ data }) => {
        setWorkflowRuns(data);
      });
  };

  useEffect(() => {
    const wfId = workflowIdFromUrl();
    if (wfId) {
      fetchWorkflow(wfId);
      fetchWorkflowRuns(wfId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDismissWorkflowRunsWarningForever = () => {
    setShowWorkflowRunsWarning(false);
    window.localStorage.setItem('showWorkflowRunsWarning', 'false');
  };

  const renderWorkflowPanel = () => {
    if (forbidden) {
      return (
        <NonIdealState
          className="non-ideal-state"
          icon="ban-circle"
          title="Cannot access this workflow"
          description="This workflow does not exist or you cannot access it."
        />
      );
    } else if (workflowCount === -1) {
      return (
        <div style={{ margin: '50vh auto' }}>
          <Spinner />
        </div>
      );
    } else if (workflowCount === 0) {
      return (
        <NonIdealState
          className="non-ideal-state"
          icon="annotation"
          title="You have no workflows"
          description={
            <>
              <p>Aaah, new beginnings.</p>
              <p>
                <Button
                  minimal
                  intent={Intent.PRIMARY}
                  onClick={() => navigate('/workflows/edit/new')}
                >
                  Create a new workflow
                </Button>
              </p>
            </>
          }
        />
      );
    } else if (!workflowId) {
      return (
        <NonIdealState
          className="non-ideal-state"
          icon="th-derived"
          title="Select a workflow"
        />
      );
    } else {
      let instructions = <H4>This workflow is autonomous</H4>;
      const instructionsPanel = (
        <div className="message-container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              maxWidth: '60%',
            }}
          >
            <div>
              {workflow?.sonans_ui_label ? (
                <Markdown remarkPlugins={[remarkGfm]}>
                  {workflow?.sonans_ui_label}
                </Markdown>
              ) : (
                instructions
              )}
            </div>
          </div>
        </div>
      );

      const executionLogPanel = (
        <>
          <H4>Execution log for this workflow</H4>
          {showWorkflowRunsWarning ? (
            <Callout
              icon={null}
              style={{ display: 'flex', margin: '1rem 0' }}
              intent={Intent.PRIMARY}
            >
              <p style={{ flex: 1, marginTop: '5px', marginLeft: '10px' }}>
                Workflows may take up to 5 minutes to start. This list updates
                automatically.
              </p>
              {user.username.indexOf('omid@sonans.ai') === 0 ? (
                <></>
              ) : (
                <div>
                  <Button
                    intent={Intent.PRIMARY}
                    minimal
                    icon="small-cross"
                    onClick={handleDismissWorkflowRunsWarningForever}
                  ></Button>
                </div>
              )}
            </Callout>
          ) : (
            <></>
          )}
          <CardList>
            <ExecutionLogEntry
              heading
              thread={{ id: 'Execution ID' }}
              status="Status"
              started_at="Started"
              completed_at="Ended"
              user={{ name: 'User' }}
            />
            {workflowRuns.length === 0 ? (
              <Card>
                <p style={{ flex: 1, textAlign: 'center', marginTop: '10px' }}>
                  This workflow has not been executed yet.
                </p>
              </Card>
            ) : (
              workflowRuns.map((entry) => <ExecutionLogEntry {...entry} />)
            )}
          </CardList>
        </>
      );
      return (
        <div className="chat-container">
          <div className="navigation">
            <Tabs>
              <Tab
                id="instructions"
                title="Instructions"
                panel={instructionsPanel}
              />
              <Tab
                id="executions"
                title="Execution log"
                panel={executionLogPanel}
              />
            </Tabs>
          </div>
        </div>
      );
    }
  };

  const renderCheckbox = () => {
    if (user?.username.indexOf('omid@sonans.ai') === 0) {
      return (
        <Tooltip
          content={`Omid, you cannot uncheck this because you need to be comfortable that we're not going to be perfect until we raise money and get actual engineer to fix bugs.`}
        >
          <Checkbox disabled>Do not show this again</Checkbox>;
        </Tooltip>
      );
    }

    return (
      <Checkbox onChange={() => setSkipPrereleaseAlert(true)}>
        Do not show this again
      </Checkbox>
    );
  };

  return (
    <div className="app-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, backgroundColor: '#2f343c' }}>
          <WorkflowList
            onWorkflowSelected={() =>
              navigate(`/workflows/${workflowIdFromUrl()}`)
            }
            onWorkflowCountUpdated={setWorkflowCount}
          />
        </div>
        <div
          style={{ height: 40, backgroundColor: '#2f343c' }}
          className="bp5-dark"
        >
          <UserChip />
        </div>
      </div>
      {isWaiting ? (
        <div style={{ margin: '50vh auto' }}>
          <Spinner />
        </div>
      ) : (
        renderWorkflowPanel()
      )}
      <Alert
        icon="warning-sign"
        confirmButtonText="I understand"
        isOpen={showPrereleaseAlert}
        onConfirm={() => handleConfirmPrerelease()}
      >
        <>
          <H4>Heads up: Sonans is pre-release software</H4>
          <p>
            This is an early pre-release version made available to our pilot
            partners for testing and feedback purposes. Some functionality may
            not be implemented yet, and you may encouter bugs, data loss, and
            other unforeseen issues.
          </p>
          <p>
            By clicking <strong>I understand</strong>, you acknowledge that you
            understand the above.
          </p>
          <p>
            Please report bugs or suggestions for improvement to your Sonans
            contact.
          </p>
          {renderCheckbox()}
        </>
      </Alert>
    </div>
  );
}
