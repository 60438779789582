export const extractMentions = (blocks) => {
  let mentions = [];

  if (!blocks) {
    return mentions;
  }

  blocks.forEach((block) => {
    block.content.forEach((content) => {
      if (content.type === 'mention') {
        mentions.push(content.props.integration);
      }
    });
  });

  return mentions;
};

export const generateInstructions = (workflowPrompt) => {
  let instructions = '';

  workflowPrompt.forEach((block) => {
    block.content.forEach((content) => {
      if (content.type === 'text') {
        instructions += content.text;
      } else if (content.type === 'mention') {
        instructions += JSON.stringify(content.props.integration);
      }
    });
    instructions += '\n\n';
  });

  return instructions;
};
