import { CardList } from '@blueprintjs/core';
import { useContext } from 'react';
import { ThirdPartyIntegrationContext } from '../../contexts/ThirdPartyIntegrationContext';

import IntegrationItem from './IntegrationItem';

export default function IntegrationList() {
  const { integrations } = useContext(ThirdPartyIntegrationContext);

  return (
    <CardList>
      {integrations.map((integration, index) => (
        <IntegrationItem
          key={index}
          logo={`https://cdn.useparagon.com/latest/dashboard/public/integrations/${integration.name}.svg`}
          name={integration.name}
          title={integration.title}
          isEnabled={integration.enabled}
        />
      ))}
    </CardList>
  );
}
