import {
  Button,
  EditableText,
  H1,
  Intent,
  MenuItem,
  TextArea,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkflowEditorView.css';
import { request } from './helpers';

export function ConfigEditor({
  configItem = null,
  onSave = () => {},
  onClose = () => {},
}) {
  const [value, setValue] = useState(
    configItem === null ? '' : configItem.value,
  );
  const [key, setKey] = useState(configItem === null ? '' : configItem.key);

  const handleSave = () => {
    onSave({ key, value });
  };

  const handleConfigValueChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <H1>
        <EditableText placeholder="Config key" value={key} onChange={setKey} />
      </H1>
      <TextArea
        value={value}
        style={{
          width: '100%',
          height: 'calc(80vh)',
          fontFamily: 'Monaco, monospace',
        }}
        onChange={handleConfigValueChange}
      ></TextArea>
      <Button intent={Intent.NONE} text="Close" onClick={onClose} />
      <Button intent={Intent.PRIMARY} text="Save" onClick={handleSave} />
    </>
  );
}

export function ConfigSelector({
  config,
  onConfigItemSelected,
  icon = null,
  text = 'Select config key',
  rightIcon = 'double-caret-vertical',
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    console.log(selectedItem);
    if (selectedItem !== null) {
      onConfigItemSelected(selectedItem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const renderConfigItem = (
    configItem,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={configItem.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={configItem.key}
      />
    );
  };

  return (
    <Select
      items={config}
      itemRenderer={renderConfigItem}
      itemsEqual={'key'}
      popoverProps={{ matchTargetWidth: true, minimal: true }}
      onItemSelect={setSelectedItem}
    >
      <Button
        icon={icon}
        rightIcon={rightIcon}
        text={text}
        fill={true}
        minimal={true}
        alignText="left"
      />
    </Select>
  );
}

export default function ConfigView() {
  const [showConfigEditor, setShowConfigEditor] = useState(false);
  const [configItems, setConfigItems] = useState([]);
  const [selectedConfigItem, setSelectedConfigItem] = useState(null);
  const navigate = useNavigate();

  const fetchConfig = () =>
    request('/config')
      .then((response) => response.json())
      .then(({ data }) => {
        setConfigItems(data);
      });

  useEffect(() => {
    fetchConfig();
  }, []);

  const handleCloseEditor = () => {
    setShowConfigEditor(false);
  };

  const handleSelect = (item) => {
    setSelectedConfigItem(item);
    setShowConfigEditor(true);
  };

  const handleSave = async (item) => {
    await request('/config', {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(item),
    });

    handleCloseEditor();
  };

  return (
    <div>
      <div className="task-list-container">
        <Button
          style={{ marginTop: 10 }}
          minimal
          icon="arrow-left"
          onClick={() => navigate('/workflows')}
          text="Workflows"
        />
        <h1>System settings</h1>
        <ConfigSelector
          config={configItems}
          onConfigItemSelected={handleSelect}
        />
        <div>
          <Button
            minimal
            disabled={showConfigEditor}
            icon="add"
            onClick={() => handleSelect(null)}
          >
            Create new config key
          </Button>
        </div>
        {showConfigEditor ? (
          <ConfigEditor
            configItem={selectedConfigItem}
            onSave={handleSave}
            onClose={() => handleCloseEditor()}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
