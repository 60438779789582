import { Card } from '@blueprintjs/core';
import IntegrationConnectButton from './IntegrationConnectButton';

export default function IntegrationItem({ logo, name, title, isEnabled }) {
  return (
    <Card>
      <img
        decoding="async"
        src={logo}
        width={24}
        alt={title}
        style={{ margin: '0 18px' }}
      />
      <div style={{ flex: 1 }}>{title}</div>
      <IntegrationConnectButton integrationType={name} isEnabled={isEnabled} />
    </Card>
  );
}
