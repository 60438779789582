import { useContext, useMemo } from 'react';

import {
  BlockNoteEditor,
  BlockNoteSchema,
  defaultInlineContentSpecs,
  filterSuggestionItems,
} from '@blocknote/core';

import '@blocknote/core/fonts/inter.css';

import {
  BlockNoteView,
  SuggestionMenuController,
  createReactInlineContentSpec,
} from '@blocknote/react';

import '@blocknote/react/style.css';

import { ThirdPartyIntegrationContext } from '../../contexts/ThirdPartyIntegrationContext';

export const Mention = createReactInlineContentSpec(
  {
    type: 'mention',
    propSchema: {
      integration: {
        default: 'Unknown',
      },
    },
    content: 'none',
  },
  {
    render: (props) => (
      <span style={{ backgroundColor: '#8400ff33' }}>
        @{props.inlineContent.props.integration.name}
      </span>
    ),
  },
);

// Our schema with inline content specs, which contain the configs and
// implementations for inline content  that we want our editor to use.
const schema = BlockNoteSchema.create({
  inlineContentSpecs: {
    // Adds all default inline content.
    ...defaultInlineContentSpecs,
    // Adds the mention tag.
    mention: Mention,
  },
});

export default function WorkflowEditor({
  initialContent,
  onChange = () => {},
}) {
  const thirdPartyIntegrationContext = useContext(ThirdPartyIntegrationContext);

  const editor = useMemo(() => {
    if (!initialContent) {
      return undefined;
    }

    return BlockNoteEditor.create({ initialContent: initialContent, schema });
  }, [initialContent]);

  if (editor === undefined) {
    return 'Loading content...';
  }

  const menuItems = (editor) => {
    const integrations = thirdPartyIntegrationContext.providersData;

    return integrations.map((integration) => ({
      title: `${integration.name} (${integration.id.length > 12 ? integration.id.substring(0, 12) + '…' : integration.id})`,
      group: integration.displayName,
      icon: (
        <img
          alt={integration.displayName}
          src={`https://cdn.useparagon.com/latest/dashboard/public/integrations/${integration.target}.svg`}
          style={{ width: 16 }}
        />
      ),
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: 'mention',
            props: {
              integration,
            },
          },
          ' ', // add a space after the mention
        ]);
      },
    }));
  };

  return (
    <BlockNoteView
      theme="light"
      editor={editor}
      onChange={() => {
        onChange(editor.document);
      }}
    >
      {/* Adds a mentions menu which opens with the "@" key */}
      <SuggestionMenuController
        triggerCharacter={'@'}
        getItems={async (query) =>
          // Gets the mentions menu items
          filterSuggestionItems(menuItems(editor), query)
        }
      />
    </BlockNoteView>
  );
}
