import { useEffect, useState } from 'react';
import { getCurrentUser } from './helpers';

export default function Internal({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser().then((data) => setUser(data));
  }, []);

  return user?.super ? children : <></>;
}
