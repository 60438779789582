import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { useEffect, useState } from 'react';
import { Megaprompt } from '../../models';

export default function WorkflowSelector({
  workflows,
  onWorkflowSelected,
  icon = null,
  text = 'Select a workflow',
  rightIcon = 'double-caret-vertical',
}) {
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const renderWorkflow = (
    workflow,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={workflow.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={workflow.name}
      />
    );
  };

  return (
    <Select
      resetOnSelect
      resetOnQuery
      resetOnClose
      activeItem={null}
      items={workflows}
      itemRenderer={renderWorkflow}
      popoverProps={{ matchTargetWidth: true, minimal: true }}
      onItemSelect={(item) => {
          const selection = new Megaprompt(JSON.parse(JSON.stringify(item)));
          setSelectedWorkflow(selection)
          onWorkflowSelected(selection);
        }
      }
    >
      <Button
        icon={icon}
        rightIcon={rightIcon}
        text={text}
        fill={true}
        minimal={true}
        alignText="left"
      />
    </Select>
  );
}
