import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import styles from './ResetPasswordView.module.css';
import { AppToaster } from './AppToaster';
import { request } from './helpers';
import AppLogo from './components/AppLogo';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function ResetPasswordView() {
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;

  const [isFormPending, setIsFormPending] = useState(false);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [hasPasswordBeenUpdated, setHasPasswordBeenUpdated] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (toasterMessage) {
      AppToaster.then((toaster) => {
        toaster.show({
          message: toasterMessage.message,
          intent: toasterMessage.intent,
        });
      });
    }
  }, [toasterMessage]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsFormPending(true);

    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());

    let intent = Intent.SUCCESS;
    let message;

    try {
      const response = await request('/reset-password', {
        method: form.method,
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(formJson),
      });

      if (!response.ok) {
        intent = Intent.DANGER;
      }

      const data = await response.json();
      message = data.message;

      setHasPasswordBeenUpdated(response.ok);
    } catch (err) {
      message = 'Something went wrong';
      intent = Intent.DANGER;
    }

    setToasterMessage({
      message,
      intent,
    });

    setIsFormPending(false);
    form.reset();
  };

  return (
    <section className={styles.container}>
      <div className={styles.headingSection}>
        <AppLogo />
        <h2>Set a new password</h2>
      </div>

      {!hasPasswordBeenUpdated && (
        <form method="post" onSubmit={handleFormSubmit}>
          <FormGroup label="New password" labelFor="form-password">
            <InputGroup
              id="form-password"
              placeholder="secret password"
              name="password"
              type="password"
              minLength={passwordMinLength}
              required
            />
          </FormGroup>
          <FormGroup label="Confirm password" labelFor="form-confirm-password">
            <InputGroup
              id="form-confirm-password"
              placeholder="secret password"
              name="confirm_password"
              type="password"
              minLength={passwordMinLength}
              required
            />
          </FormGroup>

          <input type="hidden" name="token" value={searchParams.get('token')} />

          <Button
            type="submit"
            text="Save"
            loading={isFormPending}
            fill={true}
          />
        </form>
      )}

      {hasPasswordBeenUpdated && (
        <div className={styles.goToHomepage}>
          <Button
            text="Go to Login page"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      )}
    </section>
  );
}
