import { paragon } from '@useparagon/connect';
import { getAccessToken, getCurrentUser, request } from '../helpers';

export async function getParagonUserToken() {
  try {
    const response = await request('/paragon');
    const { paragonToken } = await response.json();
    return Promise.resolve(paragonToken);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const authenticateParagon = async () => {
  const response = await request('/paragon');
  const { paragonToken } = await response.json();

  const user = await getCurrentUser();
  try {
    await paragon.authenticate(
      process.env.REACT_APP_PARAGON_PROJECT_ID,
      paragonToken,
      {
        metadata: {
          Name: user.company,
          Email: user.username,
          accessToken: getAccessToken(),
          slug: user.slug,
          env: process.env.REACT_APP_ENVIRONMENT,
        },
      },
    );
    console.log('Authenticated user on Paragon');
  } catch (err) {
    console.log(`Unable to authenticate user on Paragon: ${err}`);
  }

  return paragonToken;
};

export function getLatestCredentials(authedUser, integrationId) {
  const integrations = authedUser.integrations;
  const integrationKeys = Object.keys(integrations);
  let matchedCredentials = [];

  // Collect all matching credentials across all integrations
  integrationKeys.forEach((integrationKey) => {
    const integration = integrations[integrationKey];
    const credentialsMatchingIntegrationId = integration.allCredentials.filter(
      (credential) => {
        return credential.integrationId === integrationId;
      },
    );
    matchedCredentials = matchedCredentials.concat(
      credentialsMatchingIntegrationId,
    );
  });

  // Sort the credentials by dateCreated in descending order
  matchedCredentials.sort((a, b) => {
    return new Date(b.dateCreated) - new Date(a.dateCreated);
  });

  // Select the latest credential, if any exist
  const latestCredential =
    matchedCredentials.length > 0 ? matchedCredentials[0] : null;

  return latestCredential;
}

export async function storeParagonCredentials(integrationType, credentials) {
  const payload = {
    integration_type: integrationType,
    provider_id: credentials.providerId,
  };

  try {
    const response = await request('/third-party-integrations', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const { data } = await response.json();
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getParagonActiveCredentials() {
  try {
    const response = await request('/third-party-integrations');
    const { data } = await response.json();
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteParagonCredentials(integrationType) {
  try {
    const result = await request('/third-party-integrations', {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ integration_type: integrationType }),
    });
    const { data } = await result.json();
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}
