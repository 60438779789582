import { Button, H1 } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import ThirdPartyIntegration from '../components/ThirdPartyIntegration';
import styles from './ThirdPartyIntegrationPage.module.css';

export default function ThirdPartyIntegrationPage() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Button
        minimal
        icon="arrow-left"
        onClick={() => navigate('/workflows')}
        text="Workflows"
        className={styles.backButton}
      />
      <H1>Manage integrations</H1>
      <ThirdPartyIntegration />
    </div>
  );
}
